import { navigate } from "@reach/router"
import React, { useEffect, useState } from "react"
import LogoHeader from "../../images/GyldendalLogo1.svg"
import { fetchApi } from "../Common/WebApi-utils"
import queryString from "query-string"
import LoadingModal from "../Modals/LoadingModal"

/**
 * This component is the main header for the entire site
 *
 * @param {[]}
 * @returns {[]} returns header html
 * @version 1.0.1
 * @visibleName Main header
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
const Header = ({ setIsLogOut }) => {
  const [fullName, setFullName] = useState("")
  const [showLoggOut, setShowLoggOut] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let queryParam
    if (location.search !== null) {
      queryParam = queryString.parse(location.search)
      console.log("queryParam")
      console.log(queryParam)
      if (queryParam.token) setShowLoggOut(true)
    }

    setTimeout(function () {
      setFullName(window.sessionStorage.getItem("gyldendal_minside_userfullname"))
    }, 3000)

    if (window.sessionStorage.getItem("gyldendal_minside_access_token") !== "" && window.sessionStorage.getItem("gyldendal_minside_access_token") !== null)
      setShowLoggOut(true)
  }, [])

  const LoggOut = () => {
    setLoading(true)
    setIsLogOut(true)
    sessionStorage.clear()
    navigate(process.env.GATSBY_API_URL_ID + "/TibetIdentity/logout")
  }

  return (
    <>
      {loading && <LoadingModal />}
      <div id="gyldendal_minside_header">
        <nav className="bg-betong-300 flex">
          <div className="w-full max-w-7xl px-4 sm:px-2 lg:px-8 relative flex items-center justify-left h-16">
            <div className="flex justify-between ">
              <a href="https://gyldendal.no">
                <img className="h-8" src={LogoHeader} alt="Gyldendal" />
              </a>
            </div>
          </div>

          <div className="w-full px-4 sm:px-2 lg:px-8 relative flex justify-end">
            <div className="px-4 sm:px-2 lg:px-8 relative flex justify-end">
              <div className="relative items-center flex justify-end ">
                <p id="headerName" className="text-md">
                  {fullName}
                </p>
              </div>
            </div>
            {showLoggOut ? <>
              {/* <div className="relative items-center flex justify-end "> */}
              <div className="px-4 sm:px-2 lg:px-8 relative flex justify-end">
                <div className="relative items-center flex justify-end ">
                  <button
                    onClick={() => LoggOut()}
                    className="flex items-center justify-center px-4 py-2 bg-red-600 shadow rounded-md w-full hover:bg-pink-700"
                  >
                    <div className="text-sm font-semibold leading-tight text-white w-full">Logg ut</div>
                  </button>
                </div>
              </div>
              {/* </div> */}
            </> : null}
          </div>
        </nav>
      </div>
    </>
  )
}

export default Header
