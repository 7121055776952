import React, { useMemo, useState } from "react"
import Header from "../Headers/Header"

/**
 * This component is the main layout for the entire site
 *
 * @param {[]} children
 * @returns {[]} returns all children
 * @version 1.0.1
 * @visibleName Main layout
 * @author [Younes Hajji]
 * @author [Henrik Lie]
 */
export default function Layout({ children }) {
  const [isLogout, setIsLogOut] = useState(false)

  return (
    <div>
      <Header setIsLogOut={setIsLogOut} />
      {!isLogout ? <div>{children}</div> : null}
    </div>
  )
}
